<template>
  <div
    class="error-page"
  >
    <h1>{{ code }}</h1>
    <h2 class="h2">
      Ooops!
    </h2>
    <p class="body-1 col-3">
      {{ friendlyMessages[message] || message }}
    </p>
    <nuxt-link to="/" class="btn-primary btn-lg">
      Take me back to reality
    </nuxt-link>
    <div class="popular container">
      <h2 class="h3">
        Popular Articles from Our Help Center
      </h2>
      <div
        v-for="(a,i) in articles[$config.platform]"
        :key="i"
        class="row"
      >
        <nuxt-link :to="a.url" class="card col-8 offset-2">
          <h4
            class="h6"
            v-text="a.title"
          />
          <CarretRight />
        </nuxt-link>
      </div>
    </div>
    <nuxt-link to="/support" class="btn-primary btn-lg">
      Visit Help Center
    </nuxt-link>
  </div>
</template>

<script>
import CarretRight from '../assets/svg/carret-right.svg?vue-component';

export default {
  components: {
    CarretRight,
  },
  scrollToTop: true,
  props: {
    code: { type: Number, default: 404 },
    message: { type: String, default: '' },
  },
  data() {
    return {
      articles: {
        ds: [
          {
            url: '/support/what-is-the-365-data-science-program/',
            title: 'What is the 365 Data Science program?',
          },
          {
            url: '/support/i-am-not-familiar-with-data-science-can-i-still-subscribe-to-the-program/',
            title: 'I am not familiar with data science. Can I still subscribe to the program?',
          },
          {
            url: '/support/how-long-does-it-take-to-complete-the-program/',
            title: 'How long does it take to complete all courses in the program?',
          },
        ],
        fa: [
          {
            url: '/support/what-is-the-365-financial-analyst-program/',
            title: 'What is the 365 Financial Analyst program?',
          },
          {
            url: '/support/i-am-new-to-finance-can-i-still-subscribe-to-the-program/',
            title: 'I am new to finance. Can I still subscribe to the program?',
          },
          {
            url: '/support/how-long-does-it-take-to-complete-all-courses-in-the-program/',
            title: 'How long does it take to complete all courses in the program?',
          },
        ],
      },
    };
  },
  head() {
    return {
      titleTemplate: 'Page not found%s',
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
  computed: {
    friendlyMessages() {
      return {
        'Page not found': 'We can’t seem to find the page you are looking for.',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.error-page {
  background-color: var(--main-dark-shade-1);
  padding: 200px 0 var(--spacing-120);
  text-align: center;
  color: var(--main-light);

  h1 {
    font-size: 260px;
    line-height: 100%;
    margin: 0;
  }

  .h2 {
    margin: var(--spacing-36) 0 var(--spacing-16);
  }

  .body-1 {
    margin: 0 auto;
    padding: 0 var(--spacing-44);
  }

  .btn-primary {
    margin-top: var(--spacing-48);
    width: 300px;

    &:nth-of-type(2) {
      margin-top: var(--spacing-60);
    }
  }

  .popular {
    margin-top: var(--spacing-160);

    .h3 {
      margin: 0 0 var(--spacing-36);
    }

    .row + .row {
      margin-top: var(--spacing-24);
    }

    .card {
      background-color: var(--main-dark);
      box-shadow: 0 0 40px rgba(0, 12, 31, 0.3);
      border-radius: 7px;
      padding: 30px var(--spacing-36);
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--main-light);
      text-align: left;

      .h6 {
        margin: 0;
      }

      svg {
        flex: 0 0 auto;
      }
    }
  }
}

@include bp($bp-laptop) {
  .error-page {
    h1 {
      font-size: 240px;
    }

    .btn-primary {
      width: 260px;
    }

    .popular .h3 {
      font-size: 32px;
    }
  }
}

@include bp($bp-phone) {
  .error-page .btn-primary {
    width: 100%;
  }
}

@include bp($bp-mobile) {
  .error-page {
    padding-left: var(--spacing-24);
    padding-right: var(--spacing-24);

    h1 {
      font-size: 45vw;
    }

    .popular {
      margin-top: var(--spacing-120);
      padding: 0;

      .row {
        margin: 0;
      }

      .card {
        text-align: left;
      }
    }
  }
}

@include bp($bp-tablet) {
  .error-page {
    h1 {
      font-size: 160px;
      line-height: 120px;
    }
  }
}

.fa {
  @include bp($bp-mobile) {
    .error-page {
      .h2 {
        margin-top: var(--spacing-40);
      }
    }
  }
}
</style>
