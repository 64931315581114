export default (store) => {
  store.registerModule('tracking', {
    namespaced: true,
    state: () => ({
      event: null,
      sessionId: null,
      visitorId: null,
      pageSections: [],
    }),
    mutations: {
      setEvent(state, event) {
        state.event = event;
      },
      setSessionId(state, sessionId) {
        state.sessionId = sessionId;
      },
      setVisitorId(state, visitorId) {
        state.visitorId = visitorId;
      },
      setPageSections(state, sections) {
        state.pageSections = sections;
      },
    },
  });
};
