export default async (context) => {
  await context.$c.loadStringFile();

  if (context.route.path === '/' && context.store.state.auth.user) {
    context.redirect(context.$config.lmsUrl);
    return;
  }
  const eventID = `${context.store.state.tracking.sessionId || ''}${Date.now()}`;
  const currentUrl = context.route.name;
  const isLoggedIn = context.store.state.auth.user;
  const urlsToRedir = ['qa-hub', 'question-question', 'qa-hub-question', 'courses', 'courses-course', 'pricing', 'upcoming-courses', 'career-tracks', 'career-tracks-track', 'projects'];

  if (process.client) {
    setTimeout(() => {
      let fromUrl = null;
      const toUrl = context.route.fullPath.replace(context.route.hash, '');

      if (context.from && context.from.fullPath) {
        fromUrl = context.from.fullPath.replace(context.from.hash, '');
      }

      if (fromUrl !== toUrl) {
        context.store.commit('dataLayer/removeRegistered', 'full_scroll');
        dataLayer.push({
          event: 'Pageview', pagePath: context.route.fullPath, pageTitle: document.title, eventID,
        });
      }
    }, 2000);
  }

  if (isLoggedIn && urlsToRedir.includes(currentUrl)) {
    let redirectUrl = context.$config.lmsUrl + context.route.fullPath;
    if (currentUrl === 'courses-course') {
      const redirectUrlArray = context.route.fullPath.split('/');
      redirectUrlArray.splice(2, 0, 'preview');
      redirectUrl = context.$config.lmsUrl + redirectUrlArray.join('/');
    }
    context.redirect(301, redirectUrl);
  }

  if (context.route.path.startsWith('/i/')) {
    context.redirect(`/business/signup/?key=${context.route.params.blogSubItem}`);
  }

  if (context.route.path.startsWith('/r/')) {
    context.redirect(`/checkout/?plan=annual&rkey=${context.route.params.blogSubItem || context.route.path.split('/').filter((v) => v && v !== 'r')[0]}`);
  }

  if (context.route.path.startsWith('/ii/')) {
    context.redirect(`/instructors/signup/?key=${context.route.params.blogSubItem}`);
  }

  if (process.client) {
    const { event } = context.store.state.tracking;

    const isItABackButton = window.popStateDetected;

    window.popStateDetected = false;

    context.$tracking.resetTrackingSections();

    if (!isItABackButton) {
      if (event && !event.includes('.scroll')) {
        context.$tracking.sendEvent({
          event: context.store.state.tracking.event,
          sourceUrl: context.from.fullPath,
          destinationUrl: context.route.fullPath,
          route: context.from,
          eventID,
          capi: true,
          session: true,
          userAgent: navigator.userAgent,
        });
      }
    }
  }
};
