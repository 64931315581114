const modals = {};
let modalCounter = 0;

export const registerModal = ({ name, ...options }) => {
  if (modals[name]) throw new Error(`Modal with name "${name}" already registered`);
  modals[name] = options;
};

export const state = () => ({ opened: [] });

export const mutations = {
  open(state, modal) {
    state.opened.push(modal);
  },
  closeByKey(state, key) {
    const idx = state.opened.findIndex((modal) => modal.key === key);
    state.opened.splice(idx, 1);
  },
};

export const getters = {
  opened: ({ opened }) => opened.map(({ name, ...other }) => ({ ...modals[name], ...other })),
};

export const actions = {
  open({ commit, state }, { name, allowRedirect, ...props }) {
    if (process.server) {
      return Promise.resolve();
    }

    if (!modals[name]) {
      return Promise.reject(new Error(`Modal with name "${name}" not registered`));
    }

    const opened = state.opened.find((o) => o.name === name);

    if (opened) {
      document.body.classList.remove('body-overflow');
      commit('closeByKey', opened.key);
    }

    const key = modalCounter;
    modalCounter += 1;

    let rs;
    const promise = new Promise((resolve) => {
      rs = resolve;
      document.body.classList.add('body-overflow');
    }).finally(() => {
      document.body.classList.remove('body-overflow');
      commit('closeByKey', key);
    });
    commit('open', {
      name,
      key,
      props: {
        ...props, name, resolve: rs, reject: () => rs(false),
      },
    });

    return promise;
  },
  close({ commit, state }, { name }) {
    if (process.server) {
      return Promise.resolve();
    }

    if (!modals[name]) {
      return Promise.reject(new Error(`Modal with name "${name}" not registered`));
    }
    const opened = state.opened.find((o) => o.name === name);
    let promise;
    if (opened) {
      promise = new Promise(() => {
        document.body.classList.remove('body-overflow');
        commit('closeByKey', opened.key);
      });
    }
    return promise;
  },
};
