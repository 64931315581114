import Vue from 'vue';

export const state = () => ({
  tree: [],
});

export const mutations = {
  setArticlesTree(state, articles) {
    state.tree = articles;
  },
  toggleArticles(state, idx) {
    const { opened } = state.tree[idx];

    Vue.set(state.tree[idx], 'opened', !opened);
  },
};
