import Vue from 'vue';
import cookies from 'js-cookie';

import auth from '@itbpbg/lms-components/src/store/plugins/auth';
import { sha256 } from '../../utils/helper';

export default (store) => {
  store.registerModule('auth', {
    namespaced: true,
    state: () => ({ ...auth.state() }),
    mutations: {
      ...auth.mutations,
      setActiveSubscription(state, active) {
        Vue.set(state.user, 'activeSubscription', active);
      },
      setActiveSubscriptionPlan(state, plan) {
        state.user.activeSubscriptionPlan = plan;
      },
    },
    getters: {
      ...auth.getters,
      user(state) {
        let user = {};

        if (state.user) {
          const permissions = {
            qaHub: state.user.role === 'admin' || state.user.role === 'instructor',
          };

          user = {
            ...state.user,
            avatarUrl: `${state.user.avatarUrl}/${state.user.avatar || 'default-avatar.png'}`,
            permissions,
          };
        }
        return user;
      },
      perms(state) {
        return state.user ? (state.user.permissions || []) : [];
      },
    },
    actions: {
      ...auth.actions,
      async login({ dispatch }, {
        email, password, keepMeLoggedIn = false, resources, course,
      }) {
        try {
          cookies.remove('hideBanners', { domain: `.${this.$config.primaryCookiesDomain}` });
          const {
            data: {
              accessToken,
              refreshToken,
              user,
              message,
              firstLogin,
              status,
              error,
            } = {},
          } = await this.$axios.post(`/auth/login?keepMeLoggedIn=${keepMeLoggedIn}${resources ? '&resources=true' : ''}`, {
            email, password, course,
          }, { noError: true }).catch((err) => ({ data: { message: err.response.data.message, status: err.response.data.status, error: err.response.data.error } }));

          if (accessToken && refreshToken) {
            dispatch('setUserInfo', user);
            return true;
          }

          if (firstLogin) {
            return { firstLogin: true };
          }

          return { message: message || 'Login error. Please try again later!', status, error };
        } catch (error) {
          return error.response.data;
        }
      },
      async register({ dispatch }, {
        email, password, name, campaign, checkout, noError = false, resources, rkey, coursePurchase, course,
      }) {
        cookies.remove('hideBanners', { domain: `.${this.$config.primaryCookiesDomain}` });
        const params = [];
        if (campaign) {
          params.push('campaign=true');
        }
        if (checkout) {
          params.push('checkout=true');
        }
        if (resources) {
          params.push('resources=true');
        }
        if (rkey) {
          params.push(`rkey=${rkey}`);
        }

        if (coursePurchase) {
          params.push('course-purchase=true');
        }

        try {
          const {
            data: {
              accessToken,
              refreshToken,
              user,
              message,
            } = {},
          } = await this.$axios.post(`/auth/sign-up${params ? `?${params.join('&')}` : ''}`, {
            email, password, name, course,
          }, { noError });

          if (accessToken && refreshToken) {
            dispatch('setUserInfo', user);

            if (user?.email) {
              dataLayer.push({
                user_data: {
                  email: user.email.trim(),
                  sha256_email_address: await sha256(user.email.trim()),
                },
              });
            }

            return true;
          }

          return { message: message || 'Sign up error. Please try again later!' };
        } catch (error) {
          return error.response.data;
        }
      },
      async b2bregister({ dispatch }, {
        email, password, name, link,
      }) {
        try {
          const {
            data: {
              accessToken,
              refreshToken,
              user,
              message,
            } = {},
          } = await this.$axios.post('/business/user/signup', {
            email, password, name, link,
          });

          if (accessToken && refreshToken) {
            dispatch('setUserInfo', user);
            return true;
          }

          return { message: message || 'Sign up error. Please try again later!' };
        } catch (error) {
          return error.response.data;
        }
      },
    },
  });
};
