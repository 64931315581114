export const toURL = (url) => new URL(url.includes('://') ? url : `https://${url}`);

export const validateUrl = (urlAsString) => {
  try {
    const url = toURL(urlAsString);
    const valid = ['http:', 'https:'].includes(url.protocol);

    if (!valid) {
      return false;
    }

    return url;
  } catch (e) {
    return false;
  }
};

export const formatDateToISO = (date) => {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - offsetMs;
  const dateLocal = new Date(msLocal);
  const iso = dateLocal.toISOString();
  const isoLocal = iso.slice(0, 19);
  return isoLocal;
};

export const sha256 = async (string) => {
  let hashHex;

  try {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
  } catch (e) {
    console.log(e);
  }

  return hashHex;
};
