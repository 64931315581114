/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
export const state = () => ({
  public: [],
});

export const mutations = {
  setPublicCourses(state, courses) {
    state.public = courses;
  },
  setCurrentQuestion(state, payload) {
    state.currentQuestion = payload;
    if (process.client) {
      localStorage.setItem('currentQuestion', payload);
    }
  },
};

export const actions = {
  async fetchPublic({ commit, state }) {
    if (!state.public.length) {
      const courses = await this.$axios.$get('/courses/all');

      commit('setPublicCourses', courses);

      return courses;
    }

    return state.public;
  },
};
