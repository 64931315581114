export const state = () => ({
  tests: {},
});

export const getters = {
  getTest(state) {
    return state.tests;
  },
  loginSignupTest(state) {
    if (state.tests) {
      return state.tests['login-signup'];
    }

    return false;
  },
};

export const mutations = {
  setUserTests(state, tests) {
    state.tests = tests;
  },
  addUserTests(state, tests) {
    state.tests = { ...state.tests, ...tests };
  },
};

export const actions = {
  setUserTests({ commit }, tests) {
    commit('setUserTests', tests);
  },
  addUserTests({ commit }, tests) {
    commit('addUserTests', tests);
  },
};
