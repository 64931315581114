export const state = () => ({
  calculator: '',
  calculatorName: '',
  result: false,
  showSteps: false,
  calculationData: {},
});

export const mutations = {
  calculator(state, calculator) {
    state.calculator = calculator;
  },
  calculatorName(state, name) {
    state.calculatorName = name;
  },
  result(state, result) {
    state.result = result;
  },
  showSteps(state, boolean) {
    state.showSteps = boolean;
  },
  calculationData(state, data) {
    state.calculationData = data;
  },
};
