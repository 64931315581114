export const state = () => ({
  filters: null, answerBody: null,
});

export const mutations = {
  setAnswerBody(state, answerBody) {
    state.answerBody = answerBody;
  },
  setFilters(state, payload) {
    state.filters = payload;
  },
};
