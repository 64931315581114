export default ({ $config, store }, inject) => {
  inject('c', {
    loadStringFile: async () => {
      if (store.state.copy) {
        return true;
      }

      const copy = (await import(/* webpackChunkName: "copy" */`../copy/${$config.platform}.json`)).default;

      store.commit('setCopy', copy);

      return true;
    },
    text: (k, placeholders = {}) => {
      if (store.state.copy) {
        let text = store.state.copy[k];
        if (Object.values(placeholders).length) {
          // eslint-disable-next-line no-restricted-syntax
          for (const placeholder in placeholders) {
            if (Object.hasOwnProperty.call(placeholders, placeholder)) {
              text = text.replaceAll(`{ ${placeholder} }`, placeholders[placeholder]);
            }
          }
        }

        return text ? text.replaceAll('{ platform }', $config.platformName) : '';
      }

      return '';
    },
  });
};
