import Vue from 'vue';

Vue.filter('formatCurrency', (v, c, options = {}) => {
  const { dollarVariation = true, minimumFractionDigits = 2 } = options;

  if (!v) {
    return '-';
  }

  if (dollarVariation && (c.value === 'AUD' || c.value === 'CAD')) {
    return `${c.sign}${(+v).toLocaleString('en-US', { minimumFractionDigits, maximumFractionDigits: 2 })} ${c.value}`;
  }

  return `${c.sign}${(+v).toLocaleString('en-US', { minimumFractionDigits, maximumFractionDigits: 2 })}`;
});

Vue.filter('formatMonthPriceCurrency', (v, c) => {
  if (c.value === 'AUD' || c.value === 'CAD') {
    return `${c.value} ${v}`;
  }

  return v;
});
