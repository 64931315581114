import Vue from 'vue';
import { cloneDeep } from 'lodash-es';
import { striptags } from '@itbpbg/lms-components/src/utils/helper';

export const state = () => ({
  active: {},
  curriculum: [],
  selected: {
    sectionIdx: 0,
    sectionName: '',
    assetIdx: 0,
    asset: {},
  },
  input: '',
  activeTab: 'curriculum',
  draggingType: null,
  mode: 'builder',
  view: 'desktop',
  sendingReplyComment: false,
  hasResolvedCommentsButton: false,
  showResolvedComments: false,
  assetsMetaInfo: {},
  lockedAssets: {},
  curriculumUpdate: false,
});

export const getters = {
  questionIdx(state) {
    return state.curriculum[state.selected.sectionIdx]?.assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);
  },
  sectionName(state) {
    return state.curriculum[state.selected.sectionIdx]?.name;
  },
  sectionImage(state) {
    return state.curriculum[state.selected.sectionIdx]?.image;
  },
  assets(state) {
    const assets = [];
    for (let i = 0; i < state.curriculum.length; i += 1) {
      const section = state.curriculum[i];

      for (let j = 0; j < section.assets.length; j += 1) {
        const asset = section.assets[j];

        if (asset.type === 'text-lesson' || asset.type === 'lesson') {
          assets.push({
            id: asset.id, type: asset.type, reviews: 0, unresolvedComments: 0,
          });
        } else if (asset.type === 'exercise') {
          for (let k = 0; k < asset.questions.length; k += 1) {
            assets.push({
              id: asset.questions[k].id, type: asset.questions[k].type, reviews: 0, unresolvedComments: 0,
            });
          }
        } else if (asset.type === 'practice-exam') {
          const { summary, cases } = asset;

          if (summary.id) {
            assets.push({
              id: summary.id, type: summary.type, reviews: 0, unresolvedComments: 0,
            });
          }

          if (cases.length) {
            for (let k = 0; k < cases.length; k += 1) {
              assets.push({
                id: cases[k].description[0].id, type: cases[k].description[0].type, reviews: 0, unresolvedComments: 0,
              });

              for (let n = 0; n < cases[k].questions.length; n += 1) {
                assets.push({
                  id: cases[k].questions[n].id, type: cases[k].questions[n].type, reviews: 0, unresolvedComments: 0,
                });
              }
            }
          }
        }
      }
    }

    return assets;
  },
};

export const mutations = {
  setActive(state, payload) {
    state.active = payload;
  },
  setCurriculum(state, payload) {
    state.curriculum = payload;
  },
  addSection(state, section) {
    // state.curriculum.push(section);
    state.curriculum.splice(section.order, 0, section);
  },
  deleteSection(state, sectionIdx) {
    state.curriculum.splice(sectionIdx, 1);
  },
  addAsset(state, { sectionIdx, asset }) {
    state.curriculum[sectionIdx].assets.splice(asset.order, 0, asset);
  },
  deleteAsset(state, { sectionIdx, assetIdx }) {
    state.curriculum[sectionIdx].assets.splice(assetIdx, 1);
  },
  deleteSubAsset(state, {
    type, sectionIdx, firstParentIdx, secondParentIdx, assetIdx,
  }) {
    if (type === 'exam-question') {
      state.curriculum[sectionIdx].assets[secondParentIdx].cases[firstParentIdx].questions.splice(assetIdx, 1);
    } else if (type === 'question') {
      state.curriculum[sectionIdx].assets[firstParentIdx].questions.splice(assetIdx, 1);
    } else if (type === 'case') {
      state.curriculum[sectionIdx].assets[firstParentIdx].cases.splice(assetIdx, 1);
    }
  },
  setCurriculumAssets(state, { key, assets }) {
    state.curriculum[key].assets = assets;
  },
  setExerciseQuestions(state, { sectionIdx, assetIdx, questions }) {
    state.curriculum[sectionIdx].assets[assetIdx].questions = questions;
  },
  setPracticeExamQuestions(state, {
    sectionIdx, parentAssetIdx, assetIdx, questions,
  }) {
    state.curriculum[sectionIdx].assets[parentAssetIdx].cases[assetIdx].questions = questions;
  },
  setCases(state, { sectionIdx, assetIdx, subassets }) {
    state.curriculum[sectionIdx].assets[assetIdx].cases = subassets;
  },
  setDraggingType(state, type) {
    state.draggingType = type;
  },
  expandAllSections(state) {
    state.curriculum.forEach((section) => {
      Vue.set(section, 'expanded', true);
    });
  },
  expandSection(state, { key, force }) {
    let expand = state.curriculum[key].expanded;

    if (typeof expand === 'undefined' && typeof force === 'undefined') {
      expand = true;
    }

    Vue.set(state.curriculum[key], 'expanded', typeof force !== 'undefined' ? force : !expand);
  },
  expandAsset(state, { sectionIdx, assetIdx, force }) {
    let expand = state.curriculum[sectionIdx].assets[assetIdx].expanded;

    if (typeof expand === 'undefined' && typeof force === 'undefined') {
      expand = true;
    }

    Vue.set(state.curriculum[sectionIdx].assets[assetIdx], 'expanded', typeof force !== 'undefined' ? force : !expand);
  },
  expandSubAsset(state, {
    sectionIdx, assetIdx, parentAssetIndex, force,
  }) {
    let expand = state.curriculum[sectionIdx].assets[parentAssetIndex].cases[assetIdx].expanded;

    if (typeof expand === 'undefined' && typeof force === 'undefined') {
      expand = true;
    }

    Vue.set(state.curriculum[sectionIdx].assets[parentAssetIndex].cases[assetIdx], 'expanded', typeof force !== 'undefined' ? force : !expand);
  },
  setSectionName(state, { key, name, input }) {
    // Update section name in the database
    state.curriculum[key].name = name;

    // Update section name in the BackgroundPicker component if the changed name is for the selected section
    if (state.selected.sectionIdx === key) {
      state.selected.sectionName = name;
    }

    state.input = input || '';
  },
  setSectionImage(state, { key, image }) {
    state.curriculum[key].image = image;
  },
  setAssetName(state, {
    sectionIdx, assetIdx, name, input,
  }) {
    Vue.set(state.curriculum[sectionIdx].assets[assetIdx], 'name', name);
    state.input = input || '';
  },
  setSelectedAsset(state, {
    asset, sectionIdx, assetIdx, caseIdx, questionIdx,
  }) {
    state.selected.asset = asset;
    state.selected.sectionIdx = sectionIdx;
    if (state.activeTab === 'curriculum' && state.curriculum[sectionIdx]) {
      state.selected.sectionName = state.curriculum[sectionIdx].name;
    }
    state.selected.assetIdx = assetIdx;
    state.selected.caseIdx = caseIdx;
    state.selected.questionIdx = questionIdx;
  },
  setSelectedAssetPartially(state, asset) {
    state.selected.asset = { ...state.selected.asset, ...asset };
  },
  setActiveTab(state, tab) {
    state.activeTab = tab;
  },
  setSelectedQuestion(state, question) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx] = {
      ...state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx],
      ...question,
    };
  },
  selectQuestionType(state, type) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'subtype', type);

    if (type === 'single-choice' || type === 'true-false') {
      const answers = type === 'single-choice' ? [
        {
          value: '', explanation: '', uuid: this.$utilities.uuid(), mode: 'text',
        },
        {
          value: '', explanation: '', uuid: this.$utilities.uuid(), mode: 'text',
        },
        {
          value: '', explanation: '', uuid: this.$utilities.uuid(), mode: 'text',
        },
      ] : [
        {
          value: 'True', explanation: '', uuid: this.$utilities.uuid(), mode: 'text',
        },
        {
          value: 'False', explanation: '', uuid: this.$utilities.uuid(), mode: 'text',
        },
      ];

      Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'answers', answers);
      Vue.set(state.selected.asset, 'answers', answers);
      if (type === 'true-false') {
        Vue.set(state.selected.asset, 'correct', '0');
      } else {
        Vue.set(state.selected.asset, 'correct', null);
      }
    }

    if (type === 'multiple-choice') {
      const answers = [
        { value: '', uuid: this.$utilities.uuid(), mode: 'text' },
        { value: '', uuid: this.$utilities.uuid(), mode: 'text' },
        { value: '', uuid: this.$utilities.uuid(), mode: 'text' },
      ];

      Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'answers', answers);
      Vue.set(state.selected.asset, 'answers', answers);
      Vue.set(state.selected.asset, 'correct', []);
    }

    if (type === 'order') {
      const answers = [
        { value: '', uuid: this.$utilities.uuid(), mode: 'text' },
        { value: '', uuid: this.$utilities.uuid(), mode: 'text' },
        { value: '', uuid: this.$utilities.uuid(), mode: 'text' },
      ];

      Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'answers', answers);
      Vue.set(state.selected.asset, 'answers', answers);
    }

    if (type === 'category') {
      const categories = [{ title: '', uuid: this.$utilities.uuid() }, { title: '', uuid: this.$utilities.uuid() }]
        .map((c) => ({
          ...c,
          answers: [
            {
              value: '', uuid: this.$utilities.uuid(), categoryId: c.uuid, mode: 'text',
            },
          ],
        }));

      Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'categories', categories);
      Vue.set(state.selected.asset, 'categories', categories);
    }

    if (type === 'matrix') {
      const answer1 = {
        value: '', type: 'term', uuid: this.$utilities.uuid(), mode: 'text',
      };
      const answer2 = {
        value: '', type: 'term', uuid: this.$utilities.uuid(), mode: 'text',
      };
      const answer3 = {
        value: '', type: 'term', uuid: this.$utilities.uuid(), mode: 'text',
      };

      const answers = [
        answer1,
        {
          value: '', type: 'description', uuid: this.$utilities.uuid(), parentId: answer1.uuid, mode: 'text',
        },
        answer2,
        {
          value: '', type: 'description', uuid: this.$utilities.uuid(), parentId: answer2.uuid, mode: 'text',
        },
        answer3,
        {
          value: '', type: 'description', uuid: this.$utilities.uuid(), parentId: answer3.uuid, mode: 'text',
        },
      ];

      Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'answers', answers);
      Vue.set(state.selected.asset, 'answers', answers);
    }

    if (type === 'blank' || type === 'blank-typing') {
      Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'answers', []);
      Vue.set(state.selected.asset, 'answers', []);
      Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'blanks', []);
      Vue.set(state.selected.asset, 'blanks', []);
      Vue.set(state.selected.asset, 'correct', []);
    }

    Vue.set(state.selected.asset, 'value', '');
    Vue.set(state.selected.asset, 'subtype', type);
  },
  addQuestion(state, { sectionIdx, exerciseIdx, question }) {
    state.curriculum[sectionIdx].assets[exerciseIdx].questions.push(question);
  },
  addCaseQuestion(state, {
    parentType = 'practice-exam', sectionIdx, examIdx, caseIdx, question,
  }) {
    if (parentType === 'course-exam') {
      state.curriculum[sectionIdx].assets.push(question);
    } else {
      state.curriculum[sectionIdx].assets[examIdx].cases[caseIdx].questions.push(question);
    }
  },
  addCase(state, {
    sectionIdx, assetIdx, caseObj, type,
  }) {
    if (type === 'course') {
      state.curriculum.push(caseObj);
    } else {
      state.curriculum[sectionIdx].assets[assetIdx].cases.push(caseObj);
    }
  },
  setPracticeExamQuestionValue(state, value) {
    const { questions } = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].cases[state.selected.caseIdx ? state.selected.caseIdx : 0];
    const questionIdx = questions.findIndex((q) => q.id === state.selected.asset.id);
    questions[questionIdx].value = value;
    Vue.set(state.selected.asset, 'value', value);

    const name = (value)?.replace(/(<img[^>]+>(?:<\/img>)?)/gm, ' <i>[Image]</i> ')
      .replace(/<pre([\S\s.]+?)<\/pre>/gm, ' <i>[Code]</i> ')
      .replace(/<code class="inline-code"(.*?)<\/code>/gm, ' <i>[Code]</i> ')
      .replace(/<math-field([\S\s.]+?)<\/math-field>/gm, ' <i>[Equation]</i> ');

    questions[questionIdx].name = striptags(name, 'i');
    Vue.set(state.selected.asset, 'name', striptags(name, 'i'));
  },
  setCourseExamQuestionValue(state, value) {
    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].value = value;
    Vue.set(state.selected.asset, 'value', value);

    const name = (value)?.replace(/(<img[^>]+>(?:<\/img>)?)/gm, ' <i>[Image]</i> ')
      .replace(/<pre([\S\s.]+?)<\/pre>/gm, ' <i>[Code]</i> ')
      .replace(/<code class="inline-code"(.*?)<\/code>/gm, ' <i>[Code]</i> ')
      .replace(/<math-field([\S\s.]+?)<\/math-field>/gm, ' <i>[Equation]</i> ');

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].name = striptags(name, 'i');
    Vue.set(state.selected.asset, 'name', striptags(name, 'i'));
  },
  setQuestionValue(state, { value, selectedAsset }) {
    const sectionIdx = typeof selectedAsset?.sectionIdx !== 'undefined' && selectedAsset.sectionIdx !== state.selected.sectionIdx ? selectedAsset.sectionIdx : state.selected.sectionIdx;
    const assetIdx = typeof selectedAsset?.assetIdx !== 'undefined' && selectedAsset.assetIdx !== state.selected.assetIdx ? selectedAsset.assetIdx : state.selected.assetIdx;
    const selectedAssetId = typeof selectedAsset?.id !== 'undefined' && selectedAsset.id !== state.selected.asset.id ? selectedAsset.id : state.selected.asset.id;

    if (state.curriculum[sectionIdx].assets[assetIdx].questions) {
      const questionIdx = state.curriculum[sectionIdx].assets[assetIdx].questions.findIndex((q) => q.id === selectedAssetId);
      state.curriculum[sectionIdx].assets[assetIdx].questions[questionIdx].value = value;

      if ((selectedAsset?.id === state.selected.asset.id) || !selectedAsset) {
        Vue.set(state.selected.asset, 'value', value);
      }

      const name = (value)?.replace(/(<img[^>]+>(?:<\/img>)?)/gm, ' <i>[Image]</i> ')
        .replace(/<pre([\S\s.]+?)<\/pre>/gm, ' <i>[Code]</i> ')
        .replace(/<code class="inline-code"(.*?)<\/code>/gm, ' <i>[Code]</i> ')
        .replace(/<math-field([\S\s.]+?)<\/math-field>/gm, ' <i>[Equation]</i> ');

      state.curriculum[sectionIdx].assets[assetIdx].questions[questionIdx].name = striptags(name, 'i');

      if ((selectedAsset?.id === state.selected.asset.id) || !selectedAsset) {
        Vue.set(state.selected.asset, 'name', striptags(name, 'i'));
      }
    }
  },
  setQuestionCorrectAnswer(state, value) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].correct = value;
    state.selected.asset.correct = value;
  },
  setQuestionAnswers(state, answers) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].answers = answers;
    state.selected.asset.answers = answers;
  },
  setSelectedTaskMode(state, value) {
    Vue.set(state.selected.asset, 'selectedTaskMode', value);
  },
  setQuestionTask(state, { content, cloned = false }) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);
    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'task', content);
    Vue.set(state.selected.asset, 'task', content);

    if (cloned) {
      Vue.set(state.selected.asset, 'taskCloned', content);
    }
  },
  setCaseDescription(state, value) {
    Vue.set(state.selected.asset, 'case', value);
  },
  setVideo(state, value) {
    Vue.set(state.selected.asset, 'video', value);
  },
  setScript(state, value) {
    Vue.set(state.selected.asset, 'script', value);
  },
  setResources(state, value) {
    Vue.set(state.selected.asset, 'resources', value);
  },
  addAnswer(state) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    const answer = { value: '', explanation: '', mode: 'text' };

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].answers.push(answer);

    state.selected.asset.answers.push(answer);
  },
  removeAnswer(state, index) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].answers.splice(index, 1);
  },
  addBlank(state, blank) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].blanks.push(cloneDeep(blank));

    state.selected.asset.blanks.push(cloneDeep(blank));
  },
  removeBlank(state, id) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);
    const index = state.selected.asset.blanks.findIndex((b) => b.id === id);

    if (index !== -1) {
      const blanks = state.selected.asset.blanks.filter((b) => b.id !== id);
      state.selected.asset.blanks = cloneDeep(blanks);
      state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].blanks = cloneDeep(blanks);
    }
  },
  addBlankWrongAnswer(state, { idx, fakeId }) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    const blankId = Number(idx);

    const obj = {
      id: fakeId, value: '', correct: false, blankId,
    };

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].blanks[idx].answers.push(obj);

    state.selected.asset.blanks[idx].answers.push(obj);
  },
  removeBlankWrongAnswer(state, { idx, answerId }) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);
    const remainingAnswers = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].blanks[idx].answers.filter((v) => v.id !== answerId);

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].blanks[idx].answers = remainingAnswers;

    state.selected.asset.blanks[idx].answers = state.selected.asset.blanks[idx].answers.filter((v) => v.id !== answerId);
  },
  updateBlankAnswer(state, {
    idx, answerId, value, remainingChars,
  }) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);
    const answerIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].blanks[idx].answers.findIndex((v) => v.id === answerId);
    const selectedAnswerIdx = state.selected.asset.blanks[idx].answers.findIndex((v) => v.id === answerId);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].blanks[idx].answers[answerIdx], 'value', value);
    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].blanks[idx].answers[answerIdx], 'remainingChars', remainingChars);

    Vue.set(state.selected.asset.blanks[idx].answers[selectedAnswerIdx], 'value', value);
    Vue.set(state.selected.asset.blanks[idx].answers[selectedAnswerIdx], 'remainingChars', remainingChars);
  },
  setQuestionBlanks(state, blanks) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].blanks = cloneDeep(blanks);
    state.selected.asset.blanks = cloneDeep(blanks);
  },
  setQuestionRemainingChars(state, chars) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'remainingChars', chars);
    Vue.set(state.selected.asset, 'remainingChars', chars);
  },
  setAnswerRemainingChars(state, chars) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'answerRemainingChars', chars);
    Vue.set(state.selected.asset, 'answerRemainingChars', chars);
  },
  setCaseDescriptionRemainingChars(state, chars) {
    Vue.set(state.selected.asset, 'remainingChars', chars);
  },
  setQuestionExplanation(state, explanation) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'explanation', explanation);
    Vue.set(state.selected.asset, 'explanation', explanation);
  },
  setQuestionExplanationRemainingChars(state, chars) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx], 'explanationRemainingChars', chars);
    Vue.set(state.selected.asset, 'explanationRemainingChars', chars);
  },
  setQuestionCategories(state, categories) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].categories = categories;
    state.selected.asset.categories = categories;
  },
  addCategoryAnswer(state, categoryId) {
    const categoryIdx = state.selected.asset.categories.findIndex((c) => (c.id || c.uuid) === categoryId);

    state.selected.asset.categories[categoryIdx].answers.push({
      value: '', uuid: this.$utilities.uuid(), categoryId, mode: 'text',
    });
  },
  removeCategoryAnswer(state, { categoryId, answerId }) {
    const categoryIdx = state.selected.asset.categories.findIndex((c) => (c.id || c.uuid) === categoryId);
    const answerIdx = state.selected.asset.categories[categoryIdx].answers.findIndex((a) => (a.id || a.uuid) === answerId);

    state.selected.asset.categories[categoryIdx].answers.splice(answerIdx, 1);
  },
  setQuestionCategoryTitle(state, { value, categoryIdx }) {
    Vue.set(state.selected.asset.categories[categoryIdx], 'title', value);
  },
  setQuestionCategoryAnswer(state, { value, categoryIdx, answerIdx }) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].categories[categoryIdx].answers[answerIdx], 'value', value);
    Vue.set(state.selected.asset.categories[categoryIdx].answers[answerIdx], 'value', value);
  },
  setQuestionCategoryAnswerMode(state, { mode, categoryIdx, answerIdx }) {
    Vue.set(state.selected.asset.categories[categoryIdx].answers[answerIdx], 'mode', mode);
  },
  setCategoryAnswerRemainingChars(state, { chars, categoryId, answerId }) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);
    const categoryIdx = state.selected.asset.categories.findIndex((c) => (c.id || c.uuid) === categoryId);
    const answerIdx = state.selected.asset.categories[categoryIdx].answers.findIndex((a) => (a.id || a.uuid) === answerId);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].categories[categoryIdx].answers[answerIdx], 'remainingChars', chars);
    Vue.set(state.selected.asset.categories[categoryIdx].answers[answerIdx], 'remainingChars', chars);
  },
  setAnswerOrExplanationRemainingChars(state, {
    chars, answerIdx, type,
  }) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].answers[answerIdx], type === 'answer' ? 'remainingChars' : 'explanationRemainingChars', chars);
    Vue.set(state.selected.asset.answers[answerIdx], type === 'answer' ? 'remainingChars' : 'explanationRemainingChars', chars);
  },
  setAnswerEquationOffLimits(state, { isOffLimits, answerIdx }) {
    const questionIdx = state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions.findIndex((q) => q.id === state.selected.asset.id);

    Vue.set(state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].questions[questionIdx].answers[answerIdx], 'isOffLimits', isOffLimits);
    Vue.set(state.selected.asset.answers[answerIdx], 'isOffLimits', isOffLimits);
  },
  setCategoryAnswerEquationOffLimits(state, { isOffLimits, categoryId, answerId }) {
    const categoryIdx = state.selected.asset.categories.findIndex((c) => (c.id || c.uuid) === categoryId);
    const answerIdx = state.selected.asset.categories[categoryIdx].answers.findIndex((a) => (a.id || a.uuid) === answerId);

    Vue.set(state.selected.asset.categories[categoryIdx].answers[answerIdx], 'isOffLimits', isOffLimits);
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setView(state, view) {
    state.view = view;
  },
  setLectureTextContent(state, text) {
    state.selected.asset.text = text;
  },
  setShowResolvedComments(state, show) {
    state.showResolvedComments = show;
  },
  setHasResolvedCommentsButton(state, hasButton) {
    state.hasResolvedCommentsButton = hasButton;
  },
  setAssetsMetaInfo(state, meta) {
    state.assetsMetaInfo = meta;
  },
  increaseAssetResolvedCommentsCount(state) {
    const key = `${state.selected.asset.id}_${state.selected.asset.type}`;

    if (state.assetsMetaInfo[key]) {
      const { unresolvedComments } = state.assetsMetaInfo[key];

      state.assetsMetaInfo[key].unresolvedComments = unresolvedComments - 1;

      state.curriculum[state.selected.sectionIdx].assets[state.selected.assetIdx].unresolvedComments -= 1;
      state.selected.asset.unresolvedComments -= 1;
    }
  },
  increaseAssetReviewsCount(state) {
    const key = `${state.selected.asset.id}_${state.selected.asset.type}`;
    const { reviews, unresolvedComments } = state.assetsMetaInfo[key];
    state.assetsMetaInfo[key].reviews = reviews + 1;
    state.assetsMetaInfo[key].unresolvedComments = unresolvedComments + 1;
  },
  setLockedAssets(state, assets) {
    state.lockedAssets = assets;
  },
  unlockAsset(state, { assetId, assetType }) {
    Vue.delete(state.lockedAssets, `${assetId}_${assetType}`);
  },
  lockAsset(state, {
    assetId, assetType, userId, userAvatar,
  }) {
    Vue.set(state.lockedAssets, `${assetId}_${assetType}`, { userId, userAvatar });
  },
  removeAssetValidationErrors(state) {
    const key = `${state.selected.asset.id}_${state.selected.asset.type}`;

    if (state.assetsMetaInfo[key]) {
      state.assetsMetaInfo[key].errors = false;
    }
  },
  setCurriculumUpdate(state, value) {
    state.curriculumUpdate = value;
  },
};

export const actions = {
  async setCurriculum({ state, commit }, curriculum) {
    if (state.curriculumUpdate) {
      return;
    }

    await commit('setCurriculum', curriculum);
    const courseSlug = state.active.slug;
    commit('setCurriculumUpdate', true);
    if (state.activeTab === 'curriculum') {
      await this.$axios.$put(`/courses/${courseSlug}/curriculum/update`, { curriculum }, { progress: false });
    } else if (state.activeTab === 'exam') {
      await this.$axios.$put(`/courses/${courseSlug}/course-exam/update`, { curriculum }, { progress: false });
    }
    commit('setCurriculumUpdate', false);
  },
  async setCurriculumSubAssets({ commit }, {
    type, sectionIdx, parentAssetIdx, assetIdx, subassets,
  }) {
    if (type === 'question') {
      await commit('setExerciseQuestions', { sectionIdx, assetIdx, questions: subassets });
    } else if (type === 'exam-question') {
      const questions = subassets.filter((q) => q.type === 'question' || q.type === 'exam-question') || [];
      await commit('setPracticeExamQuestions', {
        sectionIdx, parentAssetIdx, assetIdx, questions,
      });
    } else if (type === 'case') {
      await commit('setCases', { sectionIdx, assetIdx, subassets: subassets.filter((s) => s.type === 'case') });
    }
  },
  async addSection({ commit }, section) {
    await commit('addSection', section);
  },
  async addAsset({ commit }, { asset, sectionIdx }) {
    await commit('addAsset', { asset, sectionIdx });
  },
  async addCase({ commit }, {
    sectionIdx, assetIdx, case: caseObj, type = 'practice',
  }) {
    await commit('addCase', {
      sectionIdx, assetIdx, caseObj, type,
    });
  },
  setSelectedAsset({ commit, state, rootGetters }, asset) {
    const { id: userId, avatar: userAvatar } = rootGetters['auth/user'];

    this.$socket.emit('course-builder-change-asset-edit', {
      oldAssetId: state.selected.asset.id,
      oldAssetType: state.selected.asset.type,
      newAssetId: asset.asset.id,
      newAssetType: asset.asset.type,
      userId,
      userAvatar,
    });

    commit('setSelectedAsset', asset);

    if (asset.type === 'question' || asset.asset.type === 'question') {
      commit('setSelectedQuestion', cloneDeep(asset.asset));
    }
  },
  async setSectionName({ commit }, payload) {
    await commit('setSectionName', payload);
  },
  async setAssetName({ commit }, { sectionIdx, key: assetIdx, name }) {
    await commit('setAssetName', { sectionIdx, assetIdx, name });
  },
  async selectExamQuestion(store, { examId, questionId }) {
    const question = await this.$axios.$get(`/courses/exams/${examId}/questions/${questionId}`);

    const data = { asset: { } };

    if (question) {
      data.asset.answers = question.answers;
      data.asset.value = question.question;
      data.asset.explanation = question.explanation;
      data.asset.correct = question.correct;
      data.asset.caseId = question.caseId;
      data.asset.examId = examId;
      data.asset.caseDescription = question.caseDescription;
      data.asset.explanation = question.explanation;
      data.asset.explanationDownloadables = question.explanationDownloadables;
      data.asset.reviews = question.reviews;
      data.asset.unresolvedComments = question.unresolvedComments;
    }

    return data;
  },

  async setSectionImage({ state, commit }, { sectionIdx, image }) {
    const courseSlug = state.active.slug;
    const { id } = state.curriculum[sectionIdx];

    await this.$axios.$put(`/courses/${courseSlug}/curriculum/section-image`, { id, image, courseId: state.active.id });
    commit('setSectionImage', { key: sectionIdx, image });
  },

  async postReviewComment({ state }, payload) {
    const { asset } = state.selected;

    const comment = await this.$axios.$post('/courses/review/comments', {
      comment: payload.commentText,
      assetId: asset.id,
      assetType: asset.type,
      courseId: state.active.id,
      parentId: payload.parentId,
    });

    return comment;
  },

  async setAssetsMetaInfo({ state, commit }) {
    const assets = {};

    if (state.activeTab === 'curriculum') {
      for (let i = 0; i < state.curriculum.length; i += 1) {
        const section = state.curriculum[i];

        for (let j = 0; j < section.assets.length; j += 1) {
          const asset = section.assets[j];

          if (asset.type === 'text-lesson' || asset.type === 'lesson') {
            const key = `${asset.id}_${asset.type}`;
            assets[key] = {
              reviews: state.assetsMetaInfo[key]?.reviews || asset.reviews || 0,
              unresolvedComments: typeof state.assetsMetaInfo[key]?.unresolvedComments !== 'undefined' ? state.assetsMetaInfo[key]?.unresolvedComments : (asset.unresolvedComments || 0),
              errors: typeof state.assetsMetaInfo[key]?.errors !== 'undefined' ? state.assetsMetaInfo[key]?.errors : (asset.errors || 0),
            };
          } else if (asset.type === 'exercise') {
            for (let k = 0; k < asset.questions.length; k += 1) {
              const key = `${asset.questions[k].id}_${asset.questions[k].type}`;
              assets[key] = {
                reviews: state.assetsMetaInfo[key]?.reviews || asset.questions[k].reviews || 0,
                unresolvedComments: typeof state.assetsMetaInfo[key]?.unresolvedComments !== 'undefined' ? state.assetsMetaInfo[key]?.unresolvedComments : (asset.questions[k].unresolvedComments || 0),
                errors: typeof state.assetsMetaInfo[key]?.errors !== 'undefined' ? state.assetsMetaInfo[key]?.errors : (asset.questions[k].errors || 0),
              };
            }
          } else if (asset.type === 'practice-exam') {
            const { summary, cases } = asset;

            if (summary.id) {
              const key = `${summary.id}_${summary.type}`;
              assets[key] = {
                reviews: state.assetsMetaInfo[key]?.reviews || summary.reviews || 0,
                unresolvedComments: typeof state.assetsMetaInfo[key]?.unresolvedComments !== 'undefined' ? state.assetsMetaInfo[key]?.unresolvedComments : (summary.unresolvedComments || 0),
                errors: typeof state.assetsMetaInfo[key]?.errors !== 'undefined' ? state.assetsMetaInfo[key]?.errors : (summary.errors || 0),
              };
            }

            if (cases.length) {
              for (let k = 0; k < cases.length; k += 1) {
                const key = `${cases[k].description[0].id}_${cases[k].description[0].type}`;
                assets[key] = {
                  reviews: state.assetsMetaInfo[key]?.reviews || cases[k].description[0].reviews || 0,
                  unresolvedComments: typeof state.assetsMetaInfo[key]?.unresolvedComments !== 'undefined' ? state.assetsMetaInfo[key]?.unresolvedComments : (cases[k].unresolvedComments || 0),
                  errors: typeof state.assetsMetaInfo[key]?.errors !== 'undefined' ? state.assetsMetaInfo[key]?.errors : (cases[k].description[0].errors || 0),
                };

                for (let n = 0; n < cases[k].questions.length; n += 1) {
                  const key1 = `${cases[k].questions[n].id}_${cases[k].questions[n].type}`;
                  assets[key1] = {
                    reviews: state.assetsMetaInfo[key1]?.reviews || cases[k].questions[n].reviews || 0,
                    unresolvedComments: typeof state.assetsMetaInfo[key]?.unresolvedComments !== 'undefined' ? state.assetsMetaInfo[key1]?.unresolvedComments : (cases[k].questions[n].unresolvedComments || 0),
                    errors: typeof state.assetsMetaInfo[key]?.errors !== 'undefined' ? state.assetsMetaInfo[key1]?.errors : (cases[k].questions[n].errors || 0),
                  };
                }
              }
            }
          }
        }
      }
    } else if (state.activeTab === 'exam') {
      for (let i = 0; i < state.curriculum.length; i += 1) {
        const item = state.curriculum[i];

        if (item.type === 'exam-summary') {
          const key = `${item.id}_${item.type}`;
          assets[key] = {
            reviews: state.assetsMetaInfo[key]?.reviews || item.reviews || 0,
            unresolvedComments: typeof state.assetsMetaInfo[key]?.unresolvedComments !== 'undefined' ? state.assetsMetaInfo[key]?.unresolvedComments : (item.unresolvedComments || 0),
            errors: typeof state.assetsMetaInfo[key]?.errors !== 'undefined' ? state.assetsMetaInfo[key]?.errors : (item.errors || 0),
          };
        } else if (item.type === 'case') {
          for (let j = 0; j < item.assets.length; j += 1) {
            const asset = item.assets[j];
            const key = `${asset.id}_${asset.type}`;
            assets[key] = {
              reviews: state.assetsMetaInfo[key]?.reviews || asset.reviews || 0,
              unresolvedComments: typeof state.assetsMetaInfo[key]?.unresolvedComments !== 'undefined' ? state.assetsMetaInfo[key]?.unresolvedComments : (asset.unresolvedComments || 0),
              errors: typeof state.assetsMetaInfo[key]?.errors !== 'undefined' ? state.assetsMetaInfo[key]?.errors : (asset.errors || 0),
            };
          }
        }
      }
    }

    commit('setAssetsMetaInfo', assets);
  },
};
