<template>
  <PageError :code="error.statusCode" :message="error.message" />
</template>

<script>
import PageError from '../components/PageError';

export default {
  components: {
    PageError,
  },
  props: {
    error: { type: Object, default: () => {}, required: false },
  },
  head() {
    return {
      titleTemplate: 'Page not found%s',
    };
  },
};
</script>
