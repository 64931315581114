/* eslint-disable no-underscore-dangle */

import Vue from 'vue';

if (!Vue.__seo__) {
  Vue.__seo__ = true;
  Vue.mixin({
    methods: {
      canonicalURL(forcePage) {
        const params = {};
        if (this.$route.query.query) {
          params.query = this.$route.query.query;
        }

        if (this.$route.query.search) {
          params.search = this.$route.query.search;
        }

        if (forcePage) {
          params.page = forcePage;
        } else if (this.$route.query.page) {
          params.page = this.$route.query.page;
        }

        const query = Object.entries(params).map((kv) => kv.map(encodeURIComponent).join('=')).join('&');

        return `${this.$config.siteUrl}${this.$route.path}${query ? `?${query}` : ''}`;
      },
      paginationRels(props) {
        const output = [];
        const nextPage = +props.current < Math.ceil(+props.total / +props.perPage) ? +props.current + 1 : null;
        const prevPage = +props.current > 1 ? +props.current - 1 : null;

        if (prevPage) {
          output.push({ rel: 'prev', href: this.canonicalURL(prevPage) });
        }
        if (nextPage) {
          output.push({ rel: 'next', href: this.canonicalURL(nextPage) });
        }

        return output;
      },
    },
  });
}
