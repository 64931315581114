/* eslint-disable no-restricted-globals */
if (process.client && 'scrollRestoration' in history) {
  history.scrollRestoration = 'manual';

  window.addEventListener('beforeunload', () => {
    history.scrollRestoration = 'auto';
  });

  window.addEventListener('load', () => {
    history.scrollRestoration = 'manual';
  });
}

export default (to, from, savedPosition) => {
  if (
    (from.path === '/blog/' && to.path === '/blog/')
    || (to.hash && to.path !== '/upcoming-courses/')
    || (from.name === 'resources-center-category' && to.name === 'resources-center-category')
    || (from.name === 'resources-center-category' && to.name === 'resources-center')
    || (from.name === 'resources-center' && to.name === 'resources-center-category')) {
    return false;
  }

  if (to.hash.includes('social-login')) {
    return false;
  }

  const additionalRoutes = [
    '/news/', '/trending/',
  ];
  if (((from.name === 'blogCategory-blogSubItem' && to.name === 'blogCategory-blogSubItem') || (additionalRoutes.includes(from.path) && additionalRoutes.includes(to.path))) && from.query.page !== to.query.page) {
    return false;
  }

  if (from.name === 'qa-hub' && to.name === 'qa-hub' && from.query.page !== to.query.page) {
    return false;
  }

  if (savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(savedPosition), 50);
    });
  }

  return { x: 0, y: 0 };
};
