import subscriptions from '@itbpbg/lms-components/src/store/plugins/subscriptions';

export default (store) => {
  store.registerModule('subscriptions', {
    namespaced: true,
    state: () => ({ ...subscriptions.state() }),
    mutations: { ...subscriptions.mutations },
    getters: { ...subscriptions.getters },
    actions: { ...subscriptions.actions },
  });
};
